import React, { useEffect, useRef } from "react";
import "../styles/Home.css";
import resume from "../assets/Brandon Gorman - Resume.pdf";

const Home = (props) => {
  const titlesRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (titlesRef.current) {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 100) {
          titlesRef.current.classList.add("scrolled");
        } else {
          titlesRef.current.classList.remove("scrolled");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="full-page home">
      <div className="info">
        <div className="intro-title">Hello I'm</div>
        <div className="title">
          <h1>Brandon</h1>
          <h1>Gorman</h1>
        </div>
        <div className="content">
          <div id="action-buttons" className="buttons">
            <a
              href={resume}
              target="_blank"
              rel="noreferrer"
              className="resume-button"
            >
              Download CV
            </a>
            <a
              href="mailto:bgorman@live.com"
              target="_blank"
              rel="noreferrer"
              className="contact-button"
            >
              Contact Me
            </a>
          </div>
          <div className="titles" ref={titlesRef}>
            <p>Professional Engineer</p>
            <p>Full-stack Developer</p>
          </div>
        </div>
      </div>
      <div className="">
        <div className=""></div>
      </div>
    </div>
  );
};

export default Home;
