import React from 'react'
import "../styles/Portfolio.css"
import laptopdbImage from "../assets/laptopdb.png"
import jobaidImage from "../assets/jobaid.png"
import soleReliefImage from "../assets/solerelief.png"
import pdfflowImage from "../assets/pdfflow.png"
import intrinsicImage from "../assets/Intrinsic.png"
import scormImage from "../assets/SCORM.png"

const card_data = [
  {
    id: 1,
    image: scormImage,
    title: "SCORM Virtual Tour",
    description: "An MVP of a virtual guided tour created using React Three Fiber with points of interest, quizzes to test knowledge, and a final exam for retention. SCORM compliance ensures proper progress tracking through an LMS.",
    link: { Open: "https://brandongorman.me/ole/index.html" },
    technologies: ["SCORM API", "React", "Ant Design", "React Three Fiber", "GitHub Actions"]
  },
  {
    id: 2,
    image: soleReliefImage,
    title: "SoleRelief",
    description: "Developed a WordPress website for SoleRelief, a local mobile foot care business. Managed all aspects of the project, including DNS setup, hosting, deployment, and logo recreation.",
    link: { Website: "https://www.solereliefns.ca" },
    technologies: ["WordPress", "cPanel", "DNS Management", "GoBlueBit Hosting", "Inkscape"]
  },
  {
    id: 3,
    image: intrinsicImage,
    title: "Intrinsic",
    description: "Intrinsic evaluates a stock's intrinsic value based on various metrics and displays an overall 'Quality' score. Users can search for stocks and view their financials.",
    link: { Github: "https://github.com/bgorman87/Intrinsic", Open: "https://intrinsic.brandongorman.me/" },
    technologies: ["TypeScript", "React", "Express", "Docker", "Postgres", "GitHub", "Nginx", "Apache"]
  },
  {
    id: 4,
    image: laptopdbImage,
    title: "Laptop Database",
    description: "Laptop-DB is a web app for managing laptops and parts. Users can search for laptop info by model/serial number and find associated parts.",
    link: { Github: "https://github.com/bgorman87/laptop-db", Open: "https://laptop-db.com/" },
    technologies: ["Django", "Bootstrap", "S3", "GitHub", "CodePipeline", "cPanel", "SQLite3"]
  },
  {
    id: 5,
    image: pdfflowImage,
    title: "PDF Report Processor",
    description: "Multithreaded Python tool using OCR to read PDF reports, rename them based on templates and content, sort them into folders, and email them to a contact list.",
    link: { Github: "https://github.com/bgorman87/PDF-Flow", Open: "https://pdfflow.godevservices.com/" },
    technologies: ["Python", "PySide6", "OCR", "MVVM Design", "GitHub", "SQLite3", "cx_Freeze", "AWS Services", "MySQL"]
  },
  {
    id: 6,
    image: jobaidImage,
    title: "Progressive Web Application",
    description: "Created a PWA to host job aid PDFs for various departments. Chosen for desktop and mobile compatibility, along with standalone app functionality.",
    link: "",
    technologies: ["React", "S3", "CloudFront", "Lambda@Edge", "Service Worker", "JWT", "IndexedDB"]
  }
];


const Cards = card_data.map(card => (
  <div key={card.id} className="card">
    <div className="image">
      <img src={card.image} alt={card.title} />
    </div>
    <div className="title-container">
      <div className="title">{card.title}</div>
      
      <div className="links">
        {card.link && Object.entries(card.link).map(([title, link]) => (
          <div key={title} className="link">
            <a href={link} className="link-button" target="_blank" rel="noreferrer">{title}</a>
          </div>
        ))}
      </div>
    </div>
    <hr/>
    <div className="description">{card.description}</div>
    
    <div className="technologies-title">Technologies:</div>
    <div className="technologies">
      <div className='col'>
        {card.technologies.slice(0, Math.ceil(card.technologies.length / 2)).map(tech => (
          <p key={tech}>{tech}</p>
        ))}
      </div>
      <div className='col'>
        {card.technologies.slice(Math.ceil(card.technologies.length / 2)).map(tech => (
          <p key={tech}>{tech}</p>
        ))}
      </div>
    </div>
  </div>
));


const Portfolio = () => {
  return (
    <section id="portfolio" className='full-page portfolio'>
      <div className="card-container">
        {Cards}
      </div>
    </section>
  )
}

export default Portfolio
