import Home from "./Components/Home";
import Portfolio from "./Components/Portfolio";
import "./styles/App.css";
import { useState } from "react";
import Socials from "./Components/Socials";

export default function App() {
  const [hovered, setHovered] = useState(false);
  const [splashClicked, setSplashClicked] = useState(true);

  return (
    <div className={splashClicked ? "page-container" : "page-container hidden"}>
      <div className="App">
        <div className="socials">
          <Socials />
        </div>
        <div className="content">
          <Home hovered={hovered} setHovered={setHovered} />
          <Portfolio />
        </div>
        <div className="spacer"></div>
      </div>
    </div>
  );
}
