import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";

const Socials = () => {

    return (
        <div>
            <div id="socials" className="socials">
                <div className="line before"></div>
                <div className="buttons">
                    <a
                        href="https://www.linkedin.com/in/brandon-gorman/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <div className="separator"></div>
                    <a
                        href="https://github.com/bgorman87/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon icon={faGithubSquare} />
                    </a>
                </div>
                <div className="line after"></div>
            </div>
        </div>
    );
};

export default Socials;
